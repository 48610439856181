.nav-item {
  border-radius: 6px;
  padding: 8px 0;
  &-img-wrapper {
    width: calc($aside-width - 24px);
  }
  &-img {
    width: 28px;
    height: 28px;
  }

  .menu-line {
    height: 4px;
    width: 28px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0 auto;
    background-color: $white;
  }

  .menu-line:nth-child(2) {
    margin: 3px auto;
  }
  &.menu {
    cursor: pointer;
  }
  &.active .menu-line,
  &:hover .menu-line {
    background: transparent linear-gradient(45deg, #fc6731 0%, #7633fb 100%) 0%
      0% no-repeat padding-box;
  }
}
