.nav-box {
  height: $aside-width;
  width: $aside-width;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: grid;
  place-items: center;

  img {
    width: calc($aside-width - 16px);
    height: calc($aside-width - 16px);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
}
