.profile {
  position: fixed;
  top: calc($header-nav-box-top + 15px);
  right: $header-nav-box-top;
  display: grid;
  place-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 2;

  &-container {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
  }
  &.active &-container,
  &-container:hover {
    background: transparent linear-gradient(45deg, #fc6731 0%, #7633fb 100%) 0%
      0% no-repeat padding-box;
  }
  &.active &-container svg #profile_bg,
  &-container:hover svg #profile_bg {
    fill: transparent;
  }
  &.active &-container svg #user,
  &-container:hover svg #user {
    fill: $light;
  }
  &-container svg {
    width: 42px;
    height: 42px;
  }
  .nav-item-img-wrapper {
    width: 24px;
    height: 24px;
  }
  .nav-item-img {
    width: 32px;
    height: 32px;
  }
}
