
[data-bs-theme="dark"] {
  --bs-body-bg: #{$darker} !important;
  background-color: $darker;
  --bs-body-color: #{$light} !important;
  --bs-heading-color: #{$light} !important;
  .company-logo {
    fill: $white;
  }
  .ck.ck-editor__editable p,
  .ck.ck-editor__editable h2,
  .ck.ck-editor__editable h3,
  .ck.ck-editor__editable h4 {
      color:$black!important;
  }
  
  .alertify .ajs-header {
      background-color: #fff;
      color: #000;
  }
  
  .alertify .ajs-body {
      background-color: #fff;
      color: #000; 
  }
  
  .alertify .ajs-footer .ajs-buttons .ajs-button {
      color: #fff; 
      background-color: #000;
  }
  
  .alertify .ajs-footer .ajs-buttons .ajs-button:hover {
      background-color: #ccc; 
  }
  
  aside {
    background-color: $black;
    &.active {
      background: rgba(0, 0, 0, 0.75);
    }
  }
  .popover {
    --bs-popover-bg: #{$secondary} !important;
  }
  .card {
    --bs-body-bg: $dark;
    --bs-border-color-translucent: $darker;
    background-color: $dark;
  }
  .btn:not(.btn-outline-light):hover,
  .btn:not(.btn-outline-light):focus,
  .btn-purple,
  .btn-danger,
  .btn-primary {
    --bs-btn-hover-color: #{$white};
    --bs-btn-color: #{$white};
  }
  .form-control {
    --bs-border-color: #{$muted};
    &::placeholder {
      opacity: 1;
      color: #{$light};
    }
  }
  .custom-table-bordered-head {
    --bs-table-border-color: #{$lighter};
    --bs-table-color-state: #{$lighter};

    :not(thead)tr:hover {
      background-color: $secondary;
    }
  }

  .custom-table-bordered-head thead th:nth-child(5),
  .custom-table-bordered-head thead th:nth-child(4),
  .custom-table-bordered-head thead th:nth-child(3),
  .custom-table-bordered-head thead th:nth-child(2) {
    border-bottom: 1px solid $light;
    border-top: 1px solid $light;
  }

  .custom-table-bordered-head thead th:first-child,
  .custom-table-bordered-head thead th:last-child {
    border-color: $light;
  }
  .input-group.outlined {
    --bs-border-color: #{$light};
    --bs-body-color: #{$light};
    --bs-tertiary-bg: #{$dark};
    .form-control {
      --bs-border-color: #{$light};
      --bs-body-color: #{$light};
    }
  }

  header {
    .nav-box {
      background-color: $black;
      .osi-panel {
        background-color: $black;
      }
    }
  }
  .menu-line {
    background-color: $white;
  }
  .loader {
    background-color: $black;
  }
  .sidebar {
    background: rgba(0, 0, 0, 0.75);
  }

  .pop-up {
    &-item:hover {
      cursor: pointer;
      background-color: $dark;

      svg,
      path {
        fill: $blue;
      }
    }
  }
}
