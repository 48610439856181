.login_container{
  min-height: 100vh;
  display: flex;
  width: 100%;
  background-color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-card {
    width: 400px;
    max-width: calc(100% - 15px) !important;
  
  }
}
.login_container .form-label{
  color: white;
}
.login_container img{
  width: 150px;
}