aside {
  z-index: 2;
  position: fixed;
  width: $aside-width;
  height: $aside-width;
  max-height: calc(100% - $header-nav-box-top * 2);
  top: $header-nav-box-top;
  left: $header-nav-box-top;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  padding: 12px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  .nav-item-title {
    display: none;
  }
  &.active {
    width: 300px;

    backdrop-filter: blur(6px);
    height: 100%;
    max-height: calc(100% - $header-nav-box-top * 2);
    .nav-item:not(.menu) {
      width: 175px;
    }
    .nav-item-title {
      display: block;
    }

    .nav-item:not(.menu) {
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }
    .nav-item:not(.menu)::before {
      content: "";
      width: 0%;
      position: absolute;
      height: 100%;
      z-index: -1;
      transition: width 0.5s;
      -webkit-transition: width 0.5s;
      -moz-transition: width 0.5s;
      -ms-transition: width 0.5s;
      -o-transition: width 0.5s;
    }

    .nav-item:not(.menu):hover::before {
      width: 100%;
      background: transparent linear-gradient(45deg, #fc6731 0%, #7633fb 100%)
        0% 0% no-repeat padding-box;
    }
  }

  &:not(.active) {
    .nav-item:not(.menu):hover {
      background: transparent linear-gradient(45deg, #fc6731 0%, #7633fb 100%)
        0% 0% no-repeat padding-box;
      cursor: pointer;
    }
  }
}

@media (min-width: 1200px) {
  aside {
    top: calc($aside-width + $header-nav-box-top * 2);
    height: 100%;
    max-height: calc(100% - $aside-width - $header-nav-box-top * 3);
    &.active {
      max-height: calc(100% - $aside-width - $header-nav-box-top * 3);
    }
  }
}
