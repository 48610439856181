.pop-up {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  .nav-item-img-wrapper {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
  &-item:hover {
    cursor: pointer;
    svg,
    path {
      fill: $blue;
    }
  }
}
