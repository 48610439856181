@font-face {
  font-family: montserrat;
  src: url("../../Montserrat/static/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("../../Montserrat/static/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("../../Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("../../Montserrat/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
