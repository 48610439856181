.sidebar {
  position: fixed;
  z-index: 3;
  top: $header-nav-box-top;
  right: -350px;
  height: calc(100% - $header-nav-box-top * 2);
  overflow-y: auto;
  width: 350px;
  max-width: 100%;
  transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  backdrop-filter: blur(6px);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  padding: 12px;
  &.open {
    right: $header-nav-box-top;
  }
}
