@import "typography";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "montserrat";
  scroll-behavior: smooth;
}

main {
  margin-top: $margin-top;
}

section {
  scroll-margin-top: calc($header-nav-box-top * 3 + $aside-width);
  margin-bottom: 25px;
}

table {
  border-collapse: separate;
  border-spacing: 0px;
}

table tr th:first-child {
  padding-left: 15px;
}

.custom-table-bordered-head thead th:first-child {
  border-radius: 10px 0 0 10px;
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  -ms-border-radius: 10px 0 0 10px;
  -o-border-radius: 10px 0 0 10px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
}

.custom-table-bordered-head thead th:last-child {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: none;
  border-radius: 0 10px 10px 0;
  -webkit-border-radius: 0 10px 10px 0;
  -moz-border-radius: 0 10px 10px 0;
  -ms-border-radius: 0 10px 10px 0;
  -o-border-radius: 0 10px 10px 0;
}

table tbody tr td:first-child,
table tbody tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
table tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

a {
  text-decoration: none !important;
  color: var(--bs-body-color) !important;
}
a.active .nav-item {
  background: transparent linear-gradient(45deg, #fc6731 0%, #7633fb 100%) 0% 0%
    no-repeat padding-box;
}

small {
  font-size: 0.875em;
}

.arrow.popover-arrow {
  display: none;
}

ul {
  padding: 0px !important;
}

li {
  list-style: none;
  margin-bottom: 0 !important;
}

.form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

hr {
  margin: 0px;
}

.btn-link {
  text-decoration: none;
  color: $light;
}

.input-group.outlined .form-control:not(:focus) {
  border-right: none;
}

.input-group.outlined .input-group-text {
  border-left: none;
}
.placeholder {
  max-width: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

@media (min-width: 992px) {
  section {
    scroll-margin-top: calc($header-nav-box-top * 2 + $aside-width);
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: calc(100% - $header-nav-box-top * 2);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: calc(100% - $aside-width * 2 - $header-nav-box-top * 7);
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1220px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1320px;
  }
}
