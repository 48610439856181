$color_1: official;

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-spinner {
  color: $color_1;
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  div {
    transform-origin: 15px 15px;
    animation: lds-spinner 0.9s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 1.13px;
      left: 13.88px;
      width: 2.5px;
      height: 6px;
      border-radius: 20%;
      background: $light;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.825s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -0.75s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.675s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.6s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.525s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.45s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.375s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.3s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.225s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.15s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.075s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
  &.dark {
    div {
      &:after {
        background: transparent linear-gradient(45deg, #fc6731 0%, #7633fb 100%)
          0% 0% no-repeat padding-box;
      }
    }
  }
}

.lds-spinner.large {
  color: $color_1;
  display: inline-block;
  position: relative;
  width: 70;
  height: 70px;

  div {
    transform-origin: 35px 35px;
    animation: lds-spinner 0.9s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 2.825px;
      left: 34.7px;
      width: 5px;
      height: 17.5px;
      border-radius: 20%;
      background: transparent linear-gradient(45deg, #fc6731 0%, #7633fb 100%)
        0% 0% no-repeat padding-box;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.825s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -0.75s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.675s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.6s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.525s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.45s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.375s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.3s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.225s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.15s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.075s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}
